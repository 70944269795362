import { useForm } from "react-hook-form";
import useMultiStepForm from "../../Hooks/useMultiStepForm";
import FormIntro from "./StepForms/FormIntro";
import FormOwnerDetail from "./StepForms/FormOwnerDetail";
import FormVesselDetail from "./StepForms/FormVesselDetail";
import FormEngineReRegiDetail from "./StepForms/FormEngineReRegiDetail";
import FormPayProcess from "./StepForms/FormPayProcess";
import FormUploadDoc from "./StepForms/FormUploadDoc";
import FormDeclarationLaw from "./StepForms/FormDeclarationLaw";
import usePayWay from "../../Hooks/usePayWay";
import { useGet, useMultipartFormPost } from "../../Hooks/useFetch";
import { useEffect, useState } from "react";
import SubmitLoader from "../SubmitLoader";
import FormProgressBar from "../FormProgressBar";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";
import DataLoader from "../DataLoader";
import { splitFullAddress, joinMarinaString } from "../../Helpers/Helper";
import FormOwnerDetailReadOnly from "./StepForms/FormOwnerDetailReadOnly";

const BoatReRegistrationProcess = ({ pcCraft, payWayPublicKey, hullCodes }) => {
  //console.log(pcCraft);

  const [openDialog, setOpenDialog] = useState(false);
  const [resPna, errPna, isLoadingPna, apiGetPna, abtPna] = useGet();
  const [contactError, setContactError] = useState(false);
  const [openFeeNotReadyDialog, setOpenFeeNotReadyDialog] = useState(false);
  const [isBeamEditable, setIsBeamEditable] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();

  const uploadSizeLimit = 5242880; //5MB

  let initialValues = {
    isEPIRBwithAMSA:
      pcCraft?.pcEpirb406Reg === "Y"
        ? "Yes"
        : pcCraft?.pcEpirb406Reg === "N"
        ? "No"
        : null,
    hasEPIRB:
      pcCraft?.pcEpirb === "Y" ? "Yes" : pcCraft?.pcEpirb === "N" ? "No" : null,
    epirbType: pcCraft?.pcEpirb === "Y" ? pcCraft?.pcEpirbType : null,

    mooringRegoNo: pcCraft?.moRegoNo,

    isVesselDiff: pcCraft?.pcExistingRegoNo ? "Yes" : "No",
    existRegiNo: pcCraft?.pcExistingRegoNo,

    vesselName: pcCraft?.pcName,
    classVessel: pcCraft?.pcClassCode,
    sailNumber: pcCraft?.pcClubNumber,
    clubName: pcCraft?.pcClubName,
    hullLength: pcCraft?.pcLength,
    beam: pcCraft?.pcBeam,
    draft: pcCraft?.pcDraft,
    hasNaviLight: pcCraft?.pcNavLights === "Y" ? "Yes" : pcCraft?.pcNavLights === "N" ? "No" : null,

    hullConstruction: hullCodes?.includes(pcCraft?.pcHullCode)
      ? pcCraft?.pcHullCode
      : pcCraft?.pcHullCode === "" || pcCraft?.pcHullCode == null
      ? ""
      : "OTHER",
    otherDescHullConstr: hullCodes?.includes(pcCraft?.pcHullCode)
      ? null
      : pcCraft?.pcHullCode,

    manufacturerName: pcCraft?.pcHullMake,
    hullColour: pcCraft?.pcHullColour,
    otherColour: pcCraft?.pcOtherColour,

    vesselSign: pcCraft?.pcCallSign,
    hullSerialNo: pcCraft?.pcSerialNo,
    yearOfMade: pcCraft?.pcDateManuf,
    hasBuilderPlate: pcCraft?.pcAbpFitted === "Y" ? "Yes" : pcCraft?.pcAbpFitted === "N" ? "No" : null,
    countryOfOrigin: pcCraft?.pcHin ? pcCraft?.pcHin?.split("-")[0] : null,
    hinRest: pcCraft?.pcHin ? pcCraft?.pcHin?.split("-")[1] : null,
    whereVesselKept: pcCraft?.pcStorage,
    pocodeOfStorage: pcCraft?.pcStoragePc,

    isCommercialPurpose: pcCraft?.pcPrevRegoNo ? "Yes" : null,
    comRegoNo: pcCraft?.pcPrevRegoNo,

    vesselUse: pcCraft?.pcUsage,
    model: pcCraft?.pcHullModel,
    hasFlyBridge: pcCraft?.pcFlyBridge === "Y" ? "Yes" : pcCraft?.pcFlyBridge === "N" ? "No" : null,
    isVesselHomemade: pcCraft?.pcHomemade === "Y" ? "Yes" : pcCraft?.pcHomemade === "N" ? "No" : null,
    builderName: pcCraft?.pcBuilder,
    hullLengthOverall: pcCraft?.pcLengthOverall,
    marinaName: pcCraft?.pcMarinaBerth
      ? pcCraft?.pcMarinaBerth?.split(",")[0]
      : null,
    marinaBerthNum: pcCraft?.pcMarinaBerth
      ? pcCraft?.pcMarinaBerth?.split(",")[1]
      : null,

    ownedVessel3mths: null,
    chgLocationLast3mths: null,
    chgLocationNext3mths: null,
    newLocation: null,
    vesselUnseaworthy: null,

    rb: {
      orgType: pcCraft?.orgType,
      pnaABNACN: pcCraft?.orgTypeNumber,
      fullname: pcCraft?.orgName,
      pnaEmail: pcCraft?.orgEmail,
      pnaMobile: pcCraft?.orgMobile,
      resAddr:
        pcCraft?.orgAdr1 == null ||
        pcCraft?.orgAdr1 === "" ||
        pcCraft?.orgAdr2 == null ||
        pcCraft?.orgAdr2 === "" ||
        pcCraft?.orgAdr3 == null ||
        pcCraft?.orgAdr3 === "" ||
        pcCraft?.orgPostcode == null ||
        pcCraft?.orgPostcode === ""
          ? null
          : pcCraft?.orgAdr1 +
            ", " +
            pcCraft?.orgAdr2 +
            ", " +
            pcCraft?.orgAdr3 +
            ", " +
            pcCraft?.orgPostcode,
    },

    sortRadio: pcCraft?.pcRadioType ? pcCraft?.pcRadioType?.split(",") : [],
    //file
    file: [],
    nonTasFile: [],
    isCompany: pcCraft?.isCompany,
    payId: "",
  };

  const engineArrayPropName = "engineDetails";
  initialValues[engineArrayPropName] = pcCraft?.serializePeEngines
    ? JSON.parse(pcCraft?.serializePeEngines)
    : [];

  const SecondContactArrayPropName = "secondContactDetails";
  let secondContacts = pcCraft?.serializeSecondContacts
    ? JSON.parse(pcCraft?.serializeSecondContacts)
    : [];
  // secondContacts = secondContacts?.map(contact => ({
  //   ...contact,
  //   SecondDOB: contact.SecondDOB ? dayjs(contact.SecondDOB) : null
  // }));
  initialValues[SecondContactArrayPropName] = secondContacts;

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const {
    errFrame, //err message when frame is not created
    errToken, //err message when toke is not created
    tokenId, //single token id
    creditCard, //credit card
    btnDisable, //button disable status
    frameDestroy, //it is called after send to server
    requestToken, // fc to get token
    createFrame, // fc to create card frame
    stateDestroy, //clean states
  } = usePayWay(
    //process.env.REACT_APP_PAYWAY_ID.trim()
    payWayPublicKey
  );

  const [
    resRegoFee,
    errorRegoFee,
    isLoadingRegoFee,
    apiGetRegoFee,
    abortRegoFee,
  ] = useGet();

  const isAbleRegister = () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    apiGetPna("/api/pna/PnaDecryptedData");
    apiGetRegoFee("/api/pcraft/BoatRegoFee");
    isAbleRegister();
    gaEvents.RecreationalAddVisit();

    setIsBeamEditable(getValues("beam") === "" || getValues("beam") === null);
  }, []);

  useEffect(() => {
    if (!isLoadingPna && errPna) {
      setContactError(true);
    }
  }, [isLoadingPna, errPna]);

  useEffect(() => {
    if (resRegoFee != null && resRegoFee <= 0) {
      setOpenFeeNotReadyDialog(true);
    }
  }, [resRegoFee]);

  const formContent =
    auth?.isBypassPayment === true
      ? [
          <FormIntro
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <FormOwnerDetailReadOnly
            isCompany={initialValues["isCompany"]}
            resPna={resPna}
            secondContact={secondContacts[0]}
            rb={initialValues.rb}
          />,
          <FormVesselDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            getValues={getValues}
            isReRegisterCase={true}
            isBeamEditable={isBeamEditable}
          />,
          <FormEngineReRegiDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            engineArrayPropName={engineArrayPropName}
          />,
          <FormUploadDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <FormDeclarationLaw register={register} errors={errors} />,
        ]
      : [
          <FormIntro
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <FormOwnerDetailReadOnly
            isCompany={initialValues["isCompany"]}
            resPna={resPna}
            secondContact={secondContacts[0]}
            rb={initialValues.rb}
          />,
          <FormVesselDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            getValues={getValues}
            isReRegisterCase={true}
            isBeamEditable={isBeamEditable}
          />,
          <FormEngineReRegiDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            engineArrayPropName={engineArrayPropName}
          />,
          <FormUploadDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <FormDeclarationLaw register={register} errors={errors} />,
          <FormPayProcess
            errPayWayFrame={errFrame}
            errPayWayToken={errToken}
            tokenId={tokenId}
            creditCard={creditCard}
            createFrame={createFrame}
            stateDestroy={stateDestroy}
            regoFee={resRegoFee}
            setValue={setValue}
          />,
        ];

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm(formContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  const [
    resFormPost,
    errorMsgFormPost,
    isLoadingFormPost,
    apiMultipartFormPost,
    abortFormPost,
  ] = useMultipartFormPost();

  const onSubmit = async (data, e) => {
    e.preventDefault();

    //console.log(data);
    if (
      data.file !== undefined &&
      data.file?.length <= 0 &&
      currentStepIndex === formContent.findIndex(f => f.type.name === "FormUploadDoc")
    ) {
      // console.log('file over size');
      setError("file", {
        type: "required",
        message: "Proof of Purchase is required.",
      });
      return;
    }
    
    if (data.file !== undefined && data.file[0]?.size > uploadSizeLimit) {
      // console.log('file over size');
      setError("file", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    if (
      data.nonTasFile !== undefined &&
      data.nonTasFile[0]?.size > uploadSizeLimit
    ) {
      // console.log('nonTasFile over size');
      setError("nonTasFile", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    if (!isLastStep) return next();
    if (!tokenId && !auth?.isBypassPayment) {
      requestToken();
    } else {
      //Uploading document
      let adrs = splitFullAddress(data.rb?.resAddr);

      await apiMultipartFormPost(
        "/api/pcraft/BoatReRegi",
        {
          SingleUseTokenId: data.payId,
          PcRegoNo: pcCraft?.pcRegoNo,
          PcEpirb406Reg:
            data.isEPIRBwithAMSA === "Yes"
              ? "Y"
              : data.isEPIRBwithAMSA === "No"
              ? "N"
              : null,
          PcEpirb:
            data.hasEPIRB === "Yes" ? "Y" : data.hasEPIRB === "No" ? "N" : null,
          PcEpirbType: data.hasEPIRB === "Yes" ? data.epirbType : null,
          // HdLicNum: null,
          // PcRadarType: null,
          PcRadioOpLic:
            data.sortRadio.includes("HF") || data.sortRadio.includes("VHF")
              ? "Y"
              : "N",
          MoRegoNo: data.mooringRegoNo,
          PcPrevRegoNo: data.isVesselDiff === "Yes" ? data.comRegoNo : null,
          PcName: data.vesselName,
          PcClassCode: data.classVessel,
          PcClubNumber: data.sailNumber,
          PcClubName: data.clubName,
          PcLength: data.hullLength,
          PcBeam: data.beam,
          PcDraft: data.draft,
          PcNavLights:
            data.hasNaviLight === "Yes"
              ? "Y"
              : data.hasNaviLight === "No"
              ? "N"
              : null,
          PcHullCode:
            data.hullConstruction === "OTHER"
              ? data.otherDescHullConstr
              : data.hullConstruction,
          PcHullMake: data.manufacturerName,
          PcHullColour: data.hullColour,
          PcOtherColour: data.otherColour,
          PcRadioType: data.sortRadio.join(","),
          PcCallSign: data.vesselSign,
          PcSerialNo: data.hullSerialNo,
          PcDateManuf: data.yearOfMade,
          PcAbpFitted:
            data.hasBuilderPlate === "Yes"
              ? "Y"
              : data.hasBuilderPlate === "No"
              ? "N"
              : null,
          PcHin:
            data.countryOfOrigin === "" ||
            data.countryOfOrigin == null ||
            data.hinRest === "" ||
            data.hinRest == null
              ? null
              : data.countryOfOrigin + "-" + data.hinRest,
          PcStorage: data.whereVesselKept,
          PcStoragePc: data.pocodeOfStorage,
          PcExistingRegoNo:
            data.isCommercialPurpose === "Yes" ? data.existRegiNo : null,
          PcUsage: data.vesselUse,
          PcHullModel: data.model,
          PcFlyBridge:
            data.hasFlyBridge === "Yes"
              ? "Y"
              : data.hasFlyBridge === "No"
              ? "N"
              : null,
          PcHomemade:
            data.isVesselHomemade === "Yes"
              ? "Y"
              : data.isVesselHomemade === "No"
              ? "N"
              : null,
          PcBuilder: data.builderName,
          PcLengthOverall: data.hullLengthOverall,
          PcMarinaBerth: joinMarinaString(data.marinaName, data.marinaBerthNum),
          ProofOfPurchase: data.file?.length > 0 ? data.file[0] : null,
          ProofOfVesselOutSide:
            data.nonTasFile?.length > 0 ? data.nonTasFile[0] : null,
          SerializePeEngines: JSON.stringify(data.engineDetails),

          //PcTypeOverLength: data.describeVessel === "OTHER" ? data.otherDesc15mVessel : data.describeVessel,
          PcOwned3Mths:
            data.ownedVessel3mths == null
              ? null
              : data.ownedVessel3mths === "Yes"
              ? "Y"
              : "N",
          PcChangedLoc3Mths:
            data.chgLocationLast3mths == null
              ? null
              : data.chgLocationLast3mths === "Yes"
              ? "Y"
              : "N",
          // PcStorageNow: data.vesselKeptNow,
          PcMoveNewLoc3Mths:
            data.chgLocationNext3mths == null
              ? null
              : data.chgLocationNext3mths === "Yes"
              ? "Y"
              : "N",
          PcNewLocDetail: data.newLocation == null ? null : data.newLocation,
          PcUnseaworthyCond:
            data.vesselUnseaworthy == null
              ? null
              : data.vesselUnseaworthy === "Yes"
              ? "Y"
              : "N",

          SerializeSecondContacts:
            data.isCompany === "Individual" &&
            data.secondContactDetails?.length > 0
              ? JSON.stringify(data.secondContactDetails)
              : null,
          IsCompany: data.isCompany,
          OrgName: data.rb?.fullname,
          OrgTypeNumber: data.rb?.pnaABNACN,
          OrgType: data.rb?.orgType,
          OrgAdr1: adrs != null ? adrs[0]?.trim() : null,
          OrgAdr2: adrs != null ? adrs[1]?.trim() : null,
          OrgAdr3: adrs != null ? adrs[2]?.trim() : null,
          OrgPostcode: adrs != null ? adrs[3]?.trim() : null,
          OrgEmail: data.rb?.pnaEmail,
          OrgMobile: data.rb?.pnaMobile,
        },
        // formData,
        false,
        "/boatreregiprocess/boatreregisuccess",
        gaEvents.RecreationalReRegistrationAddSuccess,
        gaEvents.RecreationalReRegistrationAddFail
      );
    }
  };

  return (
    <>
      {!isLoadingPna ? (
        !openDialog && !openFeeNotReadyDialog && !isLoadingFormPost ? (
          <div className="br-container">
            <div className="br-form-container">
              <form
                enctype="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormProgressBar
                  currentIndex={currentStepIndex}
                  totalSteps={steps}
                />
                <AlertMessage message={errorMsgFormPost} />
                <div className="re-regi-title unSelectable">
                  <span>
                    {pcCraft?.pcRegoNo ? pcCraft?.pcRegoNo : ""}
                    {pcCraft?.pcName ? " - " + pcCraft?.pcName : ""}
                    {pcCraft?.pcHullMake ? " - " + pcCraft?.pcHullMake : ""}
                  </span>
                </div>
                {stepContent}
                <div className="br-btn-group">
                  <button
                    type="button"
                    onClick={back}
                    className="br-button"
                    disabled={isFirstStep}
                  >
                    Back
                  </button>
                  {
                    <button
                      type="submit"
                      className="br-button"
                      disabled={
                        isLastStep &&
                        (btnDisable || errorRegoFee == null ? false : true)
                      }
                    >
                      {isLastStep ? (!tokenId ? "Next" : "Pay") : "Next"}
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        ) : (
          <SubmitLoader isLoading={isLoadingFormPost} />
        )
      ) : (
        <DataLoader isLoading={isLoadingPna} />
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
          redirectUrl="/recvessel"
        />
      )}

      {openFeeNotReadyDialog && (
        <Dialog
          setOpenDialog={setOpenFeeNotReadyDialog}
          title="ALERT"
          children="A system configuration error has occurred. Please contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {contactError && (
        <Dialog
          setOpenDialog={setContactError}
          title="ALERT"
          children="System cannot get login user contact, please retry again later or contact MAST."
          redirectUrl="/pna"
        />
      )}
    </>
  );
};

export default BoatReRegistrationProcess;
