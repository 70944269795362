import "./PnaHoldings.css";
import { useNavigate, useLocation } from "react-router-dom";
import { faArrowRightFromBracket, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../DataLoader";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import AlertMessage from "../AlertMessage";

const PnaHoldings = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pna/PnaHoldings");
  }, []);

  // console.log(res);

  const navigate = useNavigate();
  const location = useLocation();

  const clickNav = (destination) => {
    // console.log(destination);
    navigate("/" + destination, { state: { from: location }, replace: false });
  };

  return (
    <div className="pna-holdings-container">
      {!isLoading ? (
        !err ? (
          res ? (
            <>
              <div
                className="pna-holdings-transfer pna-holdings-box"
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="Transfer">Vessel Transfer</label>
                </div>
                <div className="pna-holding-sub-container">
                  {res?.holdingTransfers?.map((t, index) => (
                    <div className="pna-holding-field pna-holding-tf-field" key={index} onClick={() => clickNav(t?.tsfSourceLink +"/"+t?.regoNo)}>
                      <FontAwesomeIcon icon={faCircleExclamation} beat className="text-danger"/> {t?.tsfSourceLink === "transferseller" ? "(B) Approve":"(S) Approve"} {"-"} {t?.dateOfTransfer ? getddmmyyyyFormat(t?.dateOfTransfer?.split("T")[0]) : '?'}{" - "}{t?.regoNo ? t?.regoNo:'?'}{" - "}{t?.fullName ? t?.fullName:'?'}
                    </div>
                  ))}
                  {
                    res?.holdingTransfers?.length == 0 && <AlertMessage message="No Transfer in progress" level="info" closeEvt="false" mt="0" mb="0"/>
                  }
                </div>
              </div>
              <div
                className="pna-holdings-licence pna-holdings-box"
                onClick={() => clickNav("licence")}
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="Licence">Licence</label>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} beatFade />
                </div>
                <div className="pna-holding-sub-container">
                  <div className="pna-holding-field">
                    {res?.licenceNo}{res?.licenceNo && " - Expiry: "}{res?.dlExpiry ? getddmmyyyyFormat(res?.dlExpiry?.split("T")[0]) : res?.licenceNo && '?'}
                    {
                      !res?.licenceNo && <AlertMessage message="No Licence" level="info" closeEvt="false" mt="0" mb="0"/>
                    }
                  </div>
                </div>
              </div>
              <div
                className="pna-holdings-licence pna-holdings-box"
                onClick={() => clickNav("licence")}
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="Endorsements">Endorsements</label>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} beatFade />
                </div>

                <div className="pna-holding-sub-container">
                  {res?.holdingCurrentEndorsements?.map((h, index) => (
                    <div className="pna-holding-field" key={index}>
                      {/* {h?.dlCourseDate ? getddmmyyyyFormat(h?.dlCourseDate?.split("T")[0]) : '?'}{" - (Org) "}{h?.dlCourseOrg ? h?.dlCourseOrg:'?'}{" - (Instr) "}{h?.dlCourseInstr ? h?.dlCourseInstr:'?'} */}
                      {h?.dlCourseDate ? getddmmyyyyFormat(h?.dlCourseDate?.split("T")[0]) : ''}{h?.dlCourseOrg ? " - (Org) "+h?.dlCourseOrg:''}{h?.dlCourseInstr ? " - (Instr) "+h?.dlCourseInstr:''}
                    </div>
                  ))}
                  {
                    res?.holdingCurrentEndorsements?.length == 0 && <AlertMessage message="No Licence Endorsements" level="info" closeEvt="false" mt="0" mb="0"/>
                  }
                </div>
              </div>
              <div
                className="pna-holdings-rec-vessel pna-holdings-box"
                onClick={() => clickNav("recvessel")}
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="">Recreational Vessel</label>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} beatFade />
                </div>

                <div className="pna-holding-sub-container">
                  {res?.holdingPcrafts?.map((h, index) => (
                    <div className="pna-holding-field" key={index}>
                      {/* {h?.pcRego ? h?.pcRego:'?'}{" - "}{h?.pcName ? h?.pcName:'?'}{" - "}{h?.pcMake ? h?.pcMake:'?'}{" - "}{h?.pcExpiry ? getddmmyyyyFormat(h?.pcExpiry?.split("T")[0]):'?'} */}
                      {h?.pcRego ? h?.pcRego:''}{h?.pcName ? " - "+h?.pcName:''}{h?.pcMake ? " - "+h?.pcMake:''}{h?.pcExpiry ? " - "+getddmmyyyyFormat(h?.pcExpiry?.split("T")[0]):''}
                    </div>
                  ))}
                  {
                    res?.holdingPcrafts?.length == 0 && <AlertMessage message="No Vessels" level="info" closeEvt="false" mt="0" mb="0"/>
                  }
                </div>
              </div>
              <div
                className="pna-holdings-moorings pna-holdings-box"
                onClick={() => clickNav("mooring")}
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="">Moorings</label>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} beatFade />
                </div>
                <div className="pna-holding-sub-container">
                  {res?.holdingMoorings?.map((h, index) => (
                    <div className="pna-holding-field" key={index}>
                      {h?.moRego}{h?.moLocation ? " - "+h?.moLocation:''}{h?.moExpiry ? " - "+getddmmyyyyFormat(h?.moExpiry?.split("T")[0]):''}
                    </div>
                  ))}
                  {
                    res?.holdingMoorings?.length == 0 && <AlertMessage message="No Moorings" level="info" closeEvt="false" mt="0" mb="0"/>
                  }
                </div>
              </div>
              <div
                className="pna-holdings-com-vessel pna-holdings-box"
                onClick={() => clickNav("comvessel")}
              >
                <div className="pna-holdings-label-box">
                  <label htmlFor="">Commercial Vessel</label>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} beatFade />
                </div>
                <div className="pna-holding-sub-container">
                  {res?.holdingFas?.map((h, index) => (
                    <div className="pna-holding-field" key={index}>
                      {h?.faUVI ? h?.faUVI:'?'}{h?.faName ? " - "+h?.faName:''}{h?.faExpiry ? " - "+getddmmyyyyFormat(h?.faExpiry?.split("T")[0]):''}
                    </div>
                  ))}
                  {
                    res?.holdingFas?.length == 0 && <AlertMessage message="No Vessels" level="info" closeEvt="false" mt="0" mb="0"/>
                  }
                </div>
              </div>
            </>
          ) : (
            <p>No Holdings to display</p>
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <div className="center-block">
          <DataLoader isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default PnaHoldings;
